<template>
  <div>
    <div>
      <!-- <g-title>基本信息</g-title> -->
      <div class="actions flex jc-end mb-4">
        <a-button type="primary" @click="editProduction">{{ !isEditProduction ? '编辑' : '保存' }}</a-button>
      </div>
      <a-form-model ref="form" :model="formData" :rules="rules" v-bind="layout" :class="{ 'preview-mode': true }">
        <a-form-model-item label="旁白数量" prop="asideCount">
          <a-select v-if="isEditProduction" placeholder="(包括主角+配角)" v-model="formData.asideCount" allow-clear>
            <a-select-option v-for="i in asideCountOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ asideCount && asideCount.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="本土化程度" prop="localPercent">
          <a-select v-if="isEditProduction" placeholder="请选择本土化程度" v-model="formData.localPercent" allow-clear>
            <a-select-option v-for="i in localPercentOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ localPercent && localPercent.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="独立世界观" prop="hasWordOutlook" class="flex">
          <a-radio-group v-if="isEditProduction" name="radioGroup" v-model="formData.hasWordOutlook" :default-value="0">
            <a-radio :value="1"> 有 </a-radio>
            <a-radio :value="0"> 无 </a-radio>
          </a-radio-group>
          <div v-if="isEditProduction" class="inline">（游戏、非现实、非常见）</div>
          <div v-else>{{ formData.hasWordOutlook ? '有' : '无' }}</div>
        </a-form-model-item>
        <a-form-model-item label="主要人物" prop="mainRole">
          <a-select v-if="isEditProduction" placeholder="(包括主角+配角)" v-model="formData.mainRole" allow-clear>
            <a-select-option v-for="i in mainRoleOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ mainRole && mainRole.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="专业术语" prop="professionalTerm">
          <a-select
            v-if="isEditProduction"
            placeholder="请选择专业术语"
            v-model="formData.professionalTerm"
            allow-clear
          >
            <a-select-option v-for="i in professionalTermOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ professionalTerm && professionalTerm.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="其他术语" prop="otherTerm">
          <a-select v-if="isEditProduction" placeholder="请选择其他术语" v-model="formData.otherTerm" allow-clear>
            <a-select-option v-for="i in otherTermOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ otherTerm && otherTerm.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="特殊背景字" prop="specialBackgroundFont">
          <a-select
            v-if="isEditProduction"
            placeholder="请选择特殊背景字"
            v-model="formData.specialBackgroundFont"
            allow-clear
          >
            <a-select-option v-for="i in specialBackgroundFontOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ specialBackgroundFont && specialBackgroundFont.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="特殊气泡" prop="specialBubble">
          <a-select v-if="isEditProduction" placeholder="请选择特殊气泡" v-model="formData.specialBubble" allow-clear>
            <a-select-option v-for="i in specialBubbleOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ specialBubble && specialBubble.label }}</div>
        </a-form-model-item>
        <a-form-model-item label="回忆" prop="memory">
          <a-select v-if="isEditProduction" placeholder="(21-40话)" v-model="formData.memory" allow-clear>
            <a-select-option v-for="i in memoryOptions" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
          <div v-else>{{ memory && memory.label }}</div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import '@/styles/form.styl'
import { postAction, putAction } from '@/api/manage'
import infoItem from '@/components/my/info-item.vue'
import DynamicFormItem from '@/components/dynamic-form-item'

export default {
  components: {
    infoItem,
    DynamicFormItem
  },
  props: {
    isAddProduction: {
      type: Boolean,
      default: false
    },
    productionData: {
      type: Object,
      default: () => ({})
    },
    adminUsers: {
      type: Array,
      default: () => []
    },
    platformList: {
      type: Array,
      default: () => []
    },
    asideCountOptions: {
      type: Array,
      default: () => [
        { label: '20以上', value: 0 },
        { label: '13-19个', value: 1 },
        { label: '6-12个', value: 2 },
        { label: '0-5个', value: 3 }
      ]
    },
    localPercentOptions: {
      type: Array,
      default: () => [
        { label: '高', value: 0 },
        { label: '中', value: 1 },
        { label: '低', value: 2 },
        { label: '不需要', value: 3 }
      ]
    },
    mainRoleOptions: {
      type: Array,
      default: () => [
        { label: '15人以下', value: 0 },
        { label: '15-24人', value: 1 },
        { label: '8-15人', value: 2 },
        { label: '8人以下', value: 3 }
      ]
    },
    professionalTermOptions: {
      type: Array,
      default: () => [
        { label: '20个以上', value: 0 },
        { label: '15-19个', value: 1 },
        { label: '1-14个', value: 2 },
        { label: '0', value: 3 }
      ]
    },
    otherTermOptions: {
      type: Array,
      default: () => [
        { label: '25个以上', value: 0 },
        { label: '15-24个', value: 1 },
        { label: '8-14个', value: 2 },
        { label: '8个以下', value: 3 }
      ]
    },
    specialBackgroundFontOptions: {
      type: Array,
      default: () => [
        { label: '15个以上', value: 0 },
        { label: '10-14个', value: 1 },
        { label: '5-9个', value: 2 },
        { label: '0-4个', value: 3 }
      ]
    },
    specialBubbleOptions: {
      type: Array,
      default: () => [
        { label: '15个以上', value: 0 },
        { label: '10-14个', value: 1 },
        { label: '5-9个', value: 2 },
        { label: '0-4个', value: 3 }
      ]
    },
    memoryOptions: {
      type: Array,
      default: () => [
        { label: '15个以上', value: 0 },
        { label: '10-14个', value: 1 },
        { label: '1-5个', value: 2 },
        { label: '0', value: 3 }
      ]
    }
  },
  data() {
    return {
      flex: {
        type: 'flex',
        justify: 'space-between'
      },
      formData: {
        adminUser: {},
        sequenceNo: 1,
        hasChapterTitle: false,
        hasChapterCoverImage: false,
        timeBackground: [],
        storyTheme: [],
        hasWordOutlook: 0
      },
      isEditProduction: false,
      labelWidth: '80',
      layout: {
        labelCol: { style: 'width: 100px;', span: 6 },
        wrapperCol: { span: 20 }
      },
      inlineLayout: {
        labelCol: { style: 'width: 60px;', span: 1 },
        wrapperCol: {},
        colon: false
      },
      rules: {
        description: [{ required: true, message: '请填入剧情介绍', trigger: 'blur' }],
        intro: [{ required: true, message: '请填入简单概括', trigger: 'blur' }],
        localPercent: [{ required: true, message: '请选择本土化程度', trigger: 'blur' }],
        hasWordOutlook: [{ required: true, message: '请选择是否有独立世界观', trigger: 'blur' }],
        timeBackground: [
          {
            required: true,
            message: '请选择时代背景',
            trigger: 'blur',
            validator: (rule, value, callback) => {
              console.log({ rule, value, callback })
              if (!value.length) {
                callback(true)
              } else if (!value.filter(({ tag }) => tag != null || tag != undefined).length) {
                callback(true)
              } else {
                callback()
              }
            }
          }
        ],
        storyTheme: [
          {
            required: true,
            message: '请选择故事题材',
            trigger: 'blur',
            validator: (rule, value, callback) => {
              console.log({ rule, value, callback })
              if (!value.length) {
                callback(true)
              } else if (!value.filter(({ tag }) => tag != null || tag != undefined).length) {
                callback(true)
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    asideCount() {
      return this.asideCountOptions.find(({ value }) => value == this.formData.asideCount)
    },
    localPercent() {
      return this.localPercentOptions.find(({ value }) => value == this.formData.localPercent)
    },
    mainRole() {
      return this.mainRoleOptions.find(({ value }) => value == this.formData.mainRole)
    },
    professionalTerm() {
      return this.professionalTermOptions.find(({ value }) => value == this.formData.professionalTerm)
    },
    otherTerm() {
      return this.otherTermOptions.find(({ value }) => value == this.formData.otherTerm)
    },
    specialBackgroundFont() {
      return this.specialBackgroundFontOptions.find(({ value }) => value == this.formData.specialBackgroundFont)
    },
    specialBubble() {
      return this.specialBubbleOptions.find(({ value }) => value == this.formData.specialBubble)
    },
    memory() {
      return this.memoryOptions.find(({ value }) => value == this.formData.memory)
    }
  },
  methods: {
    editProduction() {
      if (this.isEditProduction) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.isEditProduction = false
          }
        })
        /*  putAction('/', ).then((res) => {
          if (res.success) {
            this.$message.success({ content: '保存成功' })
            
          }
        }) */
      } else {
        this.isEditProduction = true
      }
    },
    saveProduction(callback) {
      this.formData.adminUser.userId = this.formData.adminUserId
      this.$refs.form.validate(valid => {
        if (valid) {
          postAction('/production/add', this.formData).then(res => {
            if (res.success) {
              this.$message.success({ content: '保存成功' })
              callback(true, res.data.id)
            } else {
              this.$message.error({ content: res.message })
              callback(false)
            }
          })
        } else {
          callback(false)
        }
      })
    },
    handelPlatformChange() {
      this.platformList.forEach(one => {
        if (one.platformId == this.formData.platformId) {
          this.formData.hasChapterTitle = one.hasChapterTitle
          this.formData.hasChapterCoverImage = one.hasChapterCoverImage
        }
      })
    },
    handelFormDataChange(formData) {}
  },
  watch: {
    // formData: { deep: true, handler: 'handelFormDataChange' },
  },
  created() {}
}
</script>

<style lang="stylus"></style>
