var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "actions flex jc-end mb-4" },
          [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.editProduction } },
              [_vm._v(_vm._s(!_vm.isEditProduction ? "编辑" : "保存"))]
            ),
          ],
          1
        ),
        _c(
          "a-form-model",
          _vm._b(
            {
              ref: "form",
              class: { "preview-mode": true },
              attrs: { model: _vm.formData, rules: _vm.rules },
            },
            "a-form-model",
            _vm.layout,
            false
          ),
          [
            _c(
              "a-form-model-item",
              { attrs: { label: "旁白数量", prop: "asideCount" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "(包括主角+配角)",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.asideCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "asideCount", $$v)
                          },
                          expression: "formData.asideCount",
                        },
                      },
                      _vm._l(_vm.asideCountOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(_vm._s(_vm.asideCount && _vm.asideCount.label)),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "本土化程度", prop: "localPercent" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "请选择本土化程度",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.localPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "localPercent", $$v)
                          },
                          expression: "formData.localPercent",
                        },
                      },
                      _vm._l(_vm.localPercentOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        _vm._s(_vm.localPercent && _vm.localPercent.label)
                      ),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              {
                staticClass: "flex",
                attrs: { label: "独立世界观", prop: "hasWordOutlook" },
              },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-radio-group",
                      {
                        attrs: { name: "radioGroup", "default-value": 0 },
                        model: {
                          value: _vm.formData.hasWordOutlook,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "hasWordOutlook", $$v)
                          },
                          expression: "formData.hasWordOutlook",
                        },
                      },
                      [
                        _c("a-radio", { attrs: { value: 1 } }, [
                          _vm._v(" 有 "),
                        ]),
                        _c("a-radio", { attrs: { value: 0 } }, [
                          _vm._v(" 无 "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isEditProduction
                  ? _c("div", { staticClass: "inline" }, [
                      _vm._v("（游戏、非现实、非常见）"),
                    ])
                  : _c("div", [
                      _vm._v(_vm._s(_vm.formData.hasWordOutlook ? "有" : "无")),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "主要人物", prop: "mainRole" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "(包括主角+配角)",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.mainRole,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mainRole", $$v)
                          },
                          expression: "formData.mainRole",
                        },
                      },
                      _vm._l(_vm.mainRoleOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(_vm._s(_vm.mainRole && _vm.mainRole.label)),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "专业术语", prop: "professionalTerm" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "请选择专业术语",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.professionalTerm,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "professionalTerm", $$v)
                          },
                          expression: "formData.professionalTerm",
                        },
                      },
                      _vm._l(_vm.professionalTermOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.professionalTerm && _vm.professionalTerm.label
                        )
                      ),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "其他术语", prop: "otherTerm" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "请选择其他术语",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.otherTerm,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "otherTerm", $$v)
                          },
                          expression: "formData.otherTerm",
                        },
                      },
                      _vm._l(_vm.otherTermOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(_vm._s(_vm.otherTerm && _vm.otherTerm.label)),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "特殊背景字", prop: "specialBackgroundFont" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "请选择特殊背景字",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.specialBackgroundFont,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "specialBackgroundFont", $$v)
                          },
                          expression: "formData.specialBackgroundFont",
                        },
                      },
                      _vm._l(_vm.specialBackgroundFontOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.specialBackgroundFont &&
                            _vm.specialBackgroundFont.label
                        )
                      ),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "特殊气泡", prop: "specialBubble" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "请选择特殊气泡",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formData.specialBubble,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "specialBubble", $$v)
                          },
                          expression: "formData.specialBubble",
                        },
                      },
                      _vm._l(_vm.specialBubbleOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        _vm._s(_vm.specialBubble && _vm.specialBubble.label)
                      ),
                    ]),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "回忆", prop: "memory" } },
              [
                _vm.isEditProduction
                  ? _c(
                      "a-select",
                      {
                        attrs: { placeholder: "(21-40话)", "allow-clear": "" },
                        model: {
                          value: _vm.formData.memory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "memory", $$v)
                          },
                          expression: "formData.memory",
                        },
                      },
                      _vm._l(_vm.memoryOptions, function (i) {
                        return _c("a-select-option", { key: i.value }, [
                          _vm._v(
                            "\n            " + _vm._s(i.label) + "\n          "
                          ),
                        ])
                      }),
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.memory && _vm.memory.label))]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }